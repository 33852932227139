.background-tarif{
    background-color: #bbbbbb;
    min-height: 200px;

}

.contacts{
    text-align: center;
    margin: 50px;
}

.reseaux{
    margin:50px;
    display: flex;
    justify-content: center;
  
}

#social-media{
   
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    margin:auto;
    margin-top: 5px;
}

#logo-container{
    display: flex;
    flex-direction: column;
}

#image{
    width:50px;
    margin:5px;
}

.logo-button{
    width:50px;
    height:50px;
    margin:5px;
    background-size:cover;
    cursor:pointer;
    background-color:  #bbbbbb;
    border:none;

}

.instagram{
    background-image: url("../images/footer_images/instagram.png");

}

.youtube{
    background-image: url('../images/footer_images/youtube.png');
}

.facebook{
    background-image: url('../images/footer_images/facebook.png');
}

.facebook:hover, .youtube:hover, .instagram:hover{
    opacity: 0.7;
}

#link{
    color: black;
    font-size: 20px;
    height: 50px;
    margin:7px;
    margin-left: 10px;
 
}

#link:hover{
    opacity: 0.7;
    text-decoration:none;
}



@media screen and (max-width: 780px) {
    .contacts{
        margin-bottom: 0px;
        
    }

    

   
  }
