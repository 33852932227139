.background {
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fcf8ec;
  padding-left: 10px;
  padding-top: 30px;
}

h4 {
  color: black;
  text-align: center;
}

.title-container {
  width: fit-content;
  font-family: "Mirza", cursive;
}

.description-container {
  font-family: "Mirza", cursive;
}

.description {
  height: fit-content;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image {
  object-fit: cover;
  width: 100%;
  justify-content: center;
  margin-right: 5px;
}

.main_informations {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 70px;
}

.logo {
  width: 67px;
  height: 53px;
}

.fourth-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-around;
  margin-top: 20px;
}

.text1 {
  text-align: center;
  font-weight: 700;
  font-family: "Mirza", cursive;
}

.fitcontent {
  max-width: fit-content;
  margin: 20px;
}

.equipement1 {
  margin: 10px;
  border-radius: 10%;
  padding-top: 15px;
  max-width: 330px;
  background-color: #f1ecdf;
}

#title {
  text-decoration: underline;
}

.equipement-container {
  display: flex;
  justify-content: center;
}

#list-container {
  display: flex;
  min-width: 300px;
}

#list {
  padding: 20px;
  padding-top: 0px;
  min-width: 140px;
}

#list2 {
  padding: 20px;
  padding-top: 0px;
  min-width: 170px;
}

.subtitle {
  display: flex;
  min-width: 250px;
  margin:0px !important;
  right:4em;
}

#commerces {
  font-size: 15px;
  text-align: left;
  margin-left: 10px;
}

@media screen and (max-width: 780px) {
  .fitcontent {
    font-size: 5px;
  }
  .description {
    text-align: center;
  }
  .image {
    max-width: 90vw;
  }
}
