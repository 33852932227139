.gallery-container {
  background-color: #e7dec8;
  max-width: 100%;
  max-height: 250vh;
  min-height: 100vh;
  padding: 0px !important;
  margin-top: 80px;
}

.gallery-title {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
  margin-bottom: 0px;
  margin-top: 15px;
  width: fit-content;
}

.item {
  border: 3px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 50px;
  width: 60px;
  background-size: 100%, 100%;
  color: #394867;
  background-image: none;
}

.carousel-control-next-icon::after {
  content: ">";
  font-size: 100px;
  color:#e7dec8;
}

.carousel-control-prev-icon::after {
  content: "<";
  font-size: 100px;
  color:#e7dec8;
}

.third-row {
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;

}

.pattern {
  width: 670px;
  margin-left: auto;
  margin-right: auto;
}

.container-carousel {
  max-width: 100vw;
  display: flex;
  justify-content: center;
  min-height: 73vh;
  margin: 5px;
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.button-maison {
  padding: 30px 60px 30px 60px;
  border-radius: 40%;
  background-image: url(https://images.unsplash.com/photo-1500021804447-2ca2eaaaabeb?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80);
}

.interieur {
  font-family: "Mirza";
  font-size: 21px;
  border: solid 2px;
  cursor: pointer;
  margin: 10px;
  border-radius: 40%;
  padding: 5px 0 5px 0;
  height: 100px;
  width: 200px;
  background-image: url(../images/icones/icone_E1.jpg);
  background-size: cover;
  color: aliceblue;
}

.exterieur {
  font-family: "Mirza";
  font-size: 21px;
  border: solid 2px;
  cursor: pointer;
  margin: 10px;
  margin: 10px;
  border-radius: 40%;
  padding: 5px 0 5px 0;
  height: 100px;
  width: 200px;
  background-image: url(../images/icones/icone_E2.jpg);
  background-size: cover;
  color: aliceblue;
}

.environnement {
  font-family: "Mirza";
  font-size: 21px;
  border: solid 2px;
  cursor: pointer;
  margin: 10px;
  margin: 10px;
  border-radius: 40%;
  padding: 5px 0 5px 0;
  height: 100px;
  width: 200px;
}

.jardin {
  background-image: url(../images/icones/icone_jardin.jpg);
  background-size: cover;
  color: aliceblue;
}

.maison {
  background-image: url(../images/icones/icone_E2.jpg);
  background-size: cover;
  color: aliceblue;
}

.entourage {
  background-image: url(../images/icones/icone_ext.jpg);
  background-size: cover;
  color: aliceblue;
}

.firstfloor {
  background-image: url(../images/icones/icone_E1.jpg);
  background-size: cover;
  color: aliceblue;
}
.secondfloor {
  background-image: url(../images/icones/icone_E2.jpg);
  background-size: cover;
  color: aliceblue;
}
.RDC {
  background-image: url(../images/icones/icone_RDC.jpg);
  background-size: cover;
  color: aliceblue;
}

.interieur:hover,
.exterieur:hover,
.environnement:hover {
  border: solid 2px #394867;
}

#video {
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
  margin-bottom: 0px;
}

#video2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
  margin-bottom: 40px;
}

#title_video {
  font-size: 25px;
  margin-left: auto;
  margin-right: auto;
}

.img1 {
  min-height: fit-content;
  max-width: 100%;
}

@media screen and (max-width: 700px) {
  .third-row {
    display: flex;
    margin-top: 5px;
    padding: 0px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 900px) {
  .container-carousel {
    min-height: 56vh;
  }
}

@media screen and (max-width: 600px) {
  .container-carousel {
    min-height: 32vh;
  }
}
