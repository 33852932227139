.avis-container{
    background-color: #e7dec8;
    height: 550px;
    border:dashed #e7dec8;
    
}

.avis{
    margin:40px;
}

#text-avis{
    font-family: "Times New Roman";
    font-size: 19px;
}

#signature{
    text-align: right;
    font-family: "Times New Roman";
    font-size: 19px;
    font-weight: bold;
    
}

@media screen and (max-width: 800px) {
    #text-avis{
        font-family: "Times New Roman";
        font-size: 15px;
    }

    #signature{
      font-size: 15px;
       
        
    }
  }

@media screen and (max-width: 490px) {
    #text-avis{
        font-family: "Times New Roman";
        font-size: 13px;
    }

    #signature{
      font-size: 13px;
       
        
    }
}