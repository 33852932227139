.body{
    font-family: 'Mirza', cursive;
}

.carousel-container{
    max-width: 100%;
    min-width: 100%;
    height: 100vh;
    border: 2px;
    position: relative;
    
  }


h3{
    color:white;
}

 .picture-container{
     border: 3px;
     min-height:100%;
     max-height: 100vh;
     position: relative;
 }

 .image-carousel{
     object-fit: fill;
     width: 100%;
     min-height: 100vh;
    
 }

 .carousel-caption{
     top: 35%;
     left: 25%;
     border-left: auto;
     border-right:auto;
     position: absolute;
     color: aliceblue;
     max-width: 45%;
     border: 3px;
     width: fit-content;
    

 }

 @media screen and (max-width: 1250px) {
    .carousel-container{
        width:100%; 
        height:auto;
        

    }
  
  }

  @media screen and (max-width: 600px) {
    h1{
        font-size: 2.1rem;
    }
    h3{
      font-size: 1.1rem;
    }
  }
  