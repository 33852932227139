body{
  font-family: "Mirza";
  background-color: #fcf8ec;
}

.title{
  border:none;
  margin-left:30px;
  margin-top: 10px;
}

p{
  text-align: center;
  width:100%;
  margin:0;
  font-size: 20px;
}

.b{
  font-weight: bold;
}

.tarif_container{
  display: flex; 
  justify-content: center;
  margin-top: 50px;
  
}

.text_container{
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  text-align: left !important;
  padding:10px;
  

}

#texte_tarif{
  text-align: left;
}

.tarif_saison, .service1, .service2{
  max-height:100%;
  max-width:400px;
  object-fit: scale-down;
  margin:5px;
  
}

.service1{
  margin-top: 20px;
  max-width:350px;

}

.service2 {
  margin-top: 20px;
  max-width:300px;
  margin-bottom: 60px;;
}


@media screen and (max-width: 1250px) {
  .tarif_container{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tarif_saison{
    max-width:550px;
  
  }

  .service2{
    margin-top: 0;
  }
}

@media screen and (max-width: 800px){
  
  .tarif_saison{
    max-width:80vw;
  
  }

}



