.navbar {
  background: #394867;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  margin-top: -80px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Mirza";
}

.navbar-logo {
  color: white !important;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.5rem;
  display: flex;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(25%, 5%);
}

.navbar-logo:hover {
  opacity: 0.6;
  text-decoration: none;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 1rem;
}

.nav-item {
  height: 97px;
  color: #fff;
  cursor: pointer;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  height: 96px;
  padding-top: 17px;
}

.nav-links:hover {
  transition: all 0.2s ease-out;
  color: #fff;
  text-decoration: none;
  border-bottom: 4px solid #fff;
  opacity: 0.5;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.collasible-nav-dropdown {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  height: 87px;
}

.collasible-nav-dropdown:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  color: #fff;
  text-decoration: none;
  padding: 0;
}

#dropdown {
  background-color: unset;
  height: 96px;
  width: 100px;
  font-size: 1.2rem;
  font-family: "Mirza";
  cursor: pointer;
  padding-top: 17px;
  border: none;
}

#dropdown-close {
  background-color: unset;
  outline: unset;
  height: 87px;
  width: 150px;
}

#dropdown:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  color: #fff;
  text-decoration: none;
  padding-top: 17px;
  opacity: 0.6;
}

#dropdown:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  border: none;
  outline: none;
}

#NavDropdown-item {
  padding: 0px;
}

#link-item {
  display: block;
  text-align: center;
  max-width: 500px;
  height: 35px;
  padding-top: 5px;
  color: #242424;
}

#link-item:hover {
  background-color: #394867;
  color: #fff;
  text-decoration: none;
}

.bloc-langues {
  height: 97px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.french {
  background-image: url("../images/navbar_images/fr.png");
  background-size: cover;
  width: 28px;
  height: 18px;
  border: none;
  cursor: pointer;
  margin: 10px;
}

.english {
  background-image: url("../images/navbar_images/uk.png");
  background-size: cover;
  width: 28px;
  height: 17px;
  border: none;
  cursor: pointer;
}

.english:hover,
.french:hover {
  opacity: 0.6;
}

/*tablette*/
@media screen and (max-width: 1200px) {
  .navbar-logo {
    font-size: 1.7rem;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 35%);
  }
}

@media screen and (max-width: 1060px) {
  .navbar-logo {
    font-size: 2.1rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    justify-content: flex-start;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #394867;
    left: 0;
    opacity: 0.9;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links,
  .collasible-nav-dropdown {
    text-align: center;
    padding: 2rem;
    width: 100%;
    font-size: 1.5rem;
    display: table;
  }

  .collasible-nav-dropdown {
    padding: 0;
  }

  .nav-item:hover {
    color: rgba(255, 255, 255, 0.384);
    text-decoration: underline;
    border: red;
  }

  .nav-links:hover {
    border-bottom: none;
  }

  .collasible-nav-dropdown:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  #dropdown {
    background-color: #394867;
    border: none;
    height: 87px;
    width: 150px;
    font-size: 1.5rem;
    outline: none;
    left: 0;
    opacity: 0.9;
    transition: all 0.1s ease;
    z-index: 1;
  }

  #dropdown:hover {
    color: rgba(255, 255, 255, 0.384);
    text-decoration: underline;
    border-bottom: none;
  }

  #dropdown-close {
    background-color: unset;
    outline: unset;
    height: 87px;
    width: 150px;
    font-size: 1.5rem;
    transition: all 0.1s ease;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 35%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .navbar-logo {
    font-size: 1.6rem;
    margin: 0;
  }
}