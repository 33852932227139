
.video_page{
display: flex;
flex-direction: column;
background-color: #fcf8ec;
padding: 0px;
width:103% !important;
}

.subtitle{
    margin-left: 100px;
    font-size: 30px;
}

.video_page2{
    display: flex;
    flex-direction: column;
    background-color: none;
    padding: 0px;
    width:100% !important;
    }

